<script setup lang="ts">
const { t } = useT();
const loginGuard = useLoginGuard();
const { open } = useAppModals();
const { depositInfoData } = useRabbitDepositStreak();

const handleClick = () => {
	loginGuard({
		success: () => {
			open("LazyOModalDepositStreak");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<MMobNavBanner @click="handleClick">
		<AText :modifiers="['semibold']" class="text-cannes">
			{{ depositInfoData.canWheel ? t("depositStreak.mobNav.try") : t("depositStreak.mobNav.unlock") }}
		</AText>

		<AAnimationZoomInOut class="logo-wrap" :startScale="1" :finishScale="1.2" :duration="1">
			<NuxtImg
				src="/nuxt-img/deposit-streak/mob-nav.png"
				class="logo"
				height="200"
				width="200"
				format="avif"
				loading="lazy"
				alt="streak-nav-mob"
			/>
		</AAnimationZoomInOut>
	</MMobNavBanner>
</template>

<style lang="scss" scoped>
.logo-wrap {
	position: absolute;
	right: 16px;
	bottom: -20px;
}

.logo {
	width: 92px;
	height: auto;
}
</style>
